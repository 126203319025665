import { createTheme, responsiveFontSizes } from "@mui/material";

const primaryColor = {
  main: "#139A43",
  light: "#DBFBE6",
  contrastText: "#EEEEEE",
};

const secondaryColor = {
  main: "#F4B860",
  light: "#FAE6C6",
  contrastText: "#ffffff",
};

const themeOverrides = {
  shape: {
    borderRadius: 4,
  },
  palette: {
    mode: "light",
    primary: primaryColor,
    secondary: secondaryColor,
    dark: {
      main: "#282b28",
    },
    error: {
      main: "#FF6666",
    },
    white: {
      main: "#ffffff",
    },
    gray: {
      light: "#EEEEEE",
    },
  },
  typography: {
    h1: {
      fontWeight: "800",
      color: "black",
      fontSize: "44px",
      fontFamily: "'Open Sans', sans-serif",
    },
    h3: {
      fontWeight: 600,
      fontSize: "32px",
      lineHeight: 1.25,
      color: "#FFAC2A",
    },
  },
  components: {
    MuiLink: {
      styleOverrides: {
        root: {
          color: primaryColor.main,
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          fontSize: "18px",
        },
      },
    },
  },
};

export const darkTheme = responsiveFontSizes(
  createTheme({
    ...themeOverrides,
    palette: { ...themeOverrides.palette, mode: "dark" },
  })
);

export const lightTheme = responsiveFontSizes(
  createTheme({
    ...themeOverrides,
    palette: { ...themeOverrides.palette, mode: "light" },
  })
);

const theme = lightTheme;

export default theme;
