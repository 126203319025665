import { useRouter } from "next/router";
import { useEffect, useState } from "react";

function useSetupExperiments() {
  const { query } = useRouter();
  useEffect(() => {
    console.log("useSetupExperiments", query);
    Object.keys(query).forEach((key) => {
      if (key.startsWith("uex_")) {
        setExperiment(key.replace("uex_", ""), query[key]);
      }
    });
  }, [query]);
}

let listeners = [];

function useExperiment(experiment, allowedValues) {
  const [value, setValue] = useState(undefined);

  useEffect(() => {
    const listener = (exp, value) => {
      if (exp === experiment) setValue(value);
    };
    listeners.push(listener);

    listener(experiment, getExperiment(experiment, allowedValues));
    return () => {
      listeners = listeners.filter((l) => l !== listener);
    };
  }, [experiment, allowedValues]);
  return value;
}

function getExperiment(experiment, allowedValues) {
  if (typeof window === "undefined")
    return allowedValues ? allowedValues[0] : undefined;
  let experiments = {};
  try {
    experiments = JSON.parse(localStorage.getItem("utl_experiments")) || {};
  } catch (e) {}
  if (!allowedValues) {
    return experiments[experiment];
  } else {
    if (
      experiments[experiment] &&
      allowedValues.includes(experiments[experiment])
    )
      return experiments[experiment];
    else {
      const i = Math.floor(Math.random() * allowedValues.length);
      experiments[experiment] = allowedValues[i];
      localStorage.setItem("utl_experiments", JSON.stringify(experiments));
      return experiments[experiment];
    }
  }
}

function setExperiment(experiment, value) {
  if (typeof window === "undefined") return;
  let experiments = {};
  try {
    experiments = JSON.parse(localStorage.getItem("utl_experiments")) || {};
  } catch (e) {}
  experiments[experiment] = value;
  localStorage.setItem("utl_experiments", JSON.stringify(experiments));
  listeners.forEach((l) => l(experiment, value));
}

if (typeof window !== "undefined") {
  window.getExperiment = getExperiment;
  window.setExperiment = setExperiment;
}

export { getExperiment, setExperiment, useSetupExperiments, useExperiment };
