import { useContext, useEffect } from "react";
import { AppStateContext, SetAppStateContext } from "./marvyAppState";

export const DEFAULT_STATE = {
  tokens: null,
  user: null,
};

// export function useAppStateInit() {
//   const browser = typeof window !== "undefined";
//   if (browser && !global.localStorage["appState"])
//     global.localStorage["appState"] = JSON.stringify(DEFAULT_STATE);
//   const [appState, setAppState] = useState(
//     browser ? JSON.parse(global.localStorage["appState"]) : DEFAULT_STATE
//   );
//   useEffect(() => {
//     if (browser) {
//       global.localStorage["appState"] = JSON.stringify(appState);
//     }
//     console.log("[New State]: ", appState);
//   }, [appState, browser]);
//   return [appState, setAppState];
// }

// let changeListener;
// let state, setState;

// export function useAppStateConfig() {
//   [state, setState] = useAppStateInit();
//   useEffect(() => {
//     if (!process.browser) return;
//     changeListener = () => {
//       let newState = JSON.parse(global.localStorage["appState"]);
//       if (!_.isEqual(newState, state)) {
//         setState(newState);
//       }
//     };
//   });
//   return [state, setState];
// }

// LEGACY
// export function useAppState() {
//   return [state, setState];
// }

export function useAppState() {
  const appState = useContext(AppStateContext);
  const setAppState = useContext(SetAppStateContext);
  return [appState, setAppState];
}

export function useAppStatePersist(appState) {
  useEffect(() => {
    console.log({ appState });
    if (!appState || !appState.__initialized) return;
    const browser = typeof window !== "undefined";
    if (!browser) return;
    global.localStorage.setItem(
      "appState",
      JSON.stringify({
        ...appState,
        __initialized: undefined,
      })
    );
    console.log("savet to local storage");
  }, [appState]);
}
