export async function emitGTMEvent({ event = "no_name_event", ...data }) {
  try {
    window.dataLayer.push({
      event,
      ...data,
    });
  } catch (e) {
    console.error(e);
  }
}
